import React, { FC } from 'react';
import { ErrorObject } from 'src/shared/data-types/types';
import styled from 'styled-components';
import theme from '../../../shared/theme';

interface Props {
  className?: string;
  error?: ErrorObject | string | null;
}

const Wrapper = styled.div`
  color: ${theme.palette.error.main};
`;

const Error: FC<Props> = (props) => {
  if (!props.error && !props.children) {
    return null;
  }

  return (
    <Wrapper className={props.className} data-testid="Error">
      {props.children || (props.error as ErrorObject)?.message || `Něco se pokazilo.`}
    </Wrapper>
  );
};

export default Error;
