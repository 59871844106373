import React, { FC } from 'react';
import { globalHistory, Router, Location, navigate } from '@reach/router';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { QueryParamProvider } from 'use-query-params';

import theme from 'src/shared/theme';
import useNetlifyIdentity from 'src/shared/hooks/useNetlifyIdentity';
import PrivateRoute from './components/private-route';
import Reservations from './reservations';
import Crossroad from './crossroad';

interface Props {
  className?: string;
}

const AdminPage: FC<Props> = () => {
  useNetlifyIdentity({
    // TODO: make this init only param
    onCloseWhenLogout() {
      navigate(`/admin`);
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <Location>
          {/* To make query param provider work in build.
          Though, this is a page which should be handled by `gatsby-plugin-use-query-params` 🤔 */}
          {({ location }) => (
            <QueryParamProvider
              location={location}
              reachHistory={globalHistory}
            >
              <Router>
                <Crossroad path="/admin" />
                <PrivateRoute
                  path="/admin/rezervace/*"
                  routeWhenModalClosed="/admin"
                  component={Reservations}
                />
              </Router>
            </QueryParamProvider>
          )}
        </Location>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default AdminPage;
