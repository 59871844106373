export const OPTIONS = `options`;

export enum OPTION {
  SIMPLIFIED = `simplified`,
  TODAY = `today`,
  TOMORROW = `tomorrow`,
  DAY_AFTER_TOMORROW = `day-after-tomorrow`,
  PAST = `past`,
  FUTURE = `future`,
  CANCELED = `canceled`,

  DATE = `date`,
  START = `start`,
  END = `end`,
  CLIENTNAME = `clientName`,
  EMAIL = `email`,
  PHONE = `phone`,
  ANIMAL = `animal`,
  PURPOSE = `purpose`,
  CODE = `code`,
}

export const DEFAULT_QUERY_PARAMS = `${OPTIONS}=${OPTION.SIMPLIFIED}%2C${OPTION.TODAY}`;
