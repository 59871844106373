import netlifyIdentity, { User } from 'netlify-identity-widget';
import { useEffect, useState } from 'react';
import useIsMountedRef from './useIsMountedRef';

interface Props {
  onCloseWhenLogout?(): void;
}

let netlifyIdentityInitialized = false;
let userRef;

export default function useNetlifyIdentity(
  { onCloseWhenLogout } = {} as Props
) {
  const [currentUser, setCurrentUser] = useState<User | null>(
    netlifyIdentity.currentUser()
  );

  const isMounted = useIsMountedRef();

  useEffect(() => {
    if (!netlifyIdentityInitialized) {
      if (typeof window !== `undefined`) {
        netlifyIdentity.init();
        netlifyIdentityInitialized = true;
      }
    }

    function handleUserLogin(user?) {
      userRef = user;
      netlifyIdentity.close();

      if (isMounted) {
        setCurrentUser(user);
      }
    }

    function handleLogout() {
      userRef = null;
      netlifyIdentity.close();

      if (isMounted) {
        setCurrentUser(null);
      }
    }

    function handleClose() {
      if (!userRef && onCloseWhenLogout) {
        onCloseWhenLogout();
      }
    }

    function handleInit(userAfterInit) {
      userRef = userAfterInit;
    }

    netlifyIdentity.on(`login`, handleUserLogin);
    netlifyIdentity.on(`logout`, handleLogout);
    netlifyIdentity.on(`close`, handleClose);
    netlifyIdentity.on(`init`, handleInit);

    return () => {
      netlifyIdentity.off(`login`, handleUserLogin);
      netlifyIdentity.off(`logout`, handleLogout);
      netlifyIdentity.off(`close`, handleClose);
      netlifyIdentity.off(`init`, handleInit);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user: currentUser, netlifyIdentity };
}
