import { OPTION } from "../query-params";

export function excludeAllFuture(filters: OPTION[]) {
  return filters.filter(
    f =>
      !isFuture(f)
  );
}

export function isFuture(tag: OPTION) {
  return tag === OPTION.TODAY || tag === OPTION.TOMORROW || tag === OPTION.DAY_AFTER_TOMORROW;
}
