import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import useNetlifyIdentity from 'src/shared/hooks/useNetlifyIdentity';

interface Props extends RouteComponentProps {
  component: any;
  routeWhenModalClosed: string;
}

const PrivateRoute: FC<Props> = ({
  component: Component,
  ...rest
}) => {
  const { user: isLoggedIn, netlifyIdentity } = useNetlifyIdentity();

  useEffect(() => {
    if (!isLoggedIn) {
      netlifyIdentity.open();
    }
  }, [isLoggedIn, netlifyIdentity]);

  return isLoggedIn ? <Component {...rest} /> : null;
};

export default PrivateRoute;
