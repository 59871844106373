import React, { FC, useEffect, useState } from 'react';
import { css } from 'styled-components';
import { useQueryParam, withDefault } from 'use-query-params';

import Brand from 'components/shared/layout/brand';
import theme from 'src/shared/theme';
import { XS } from 'src/shared/media-query';
import useFetch from 'src/shared/hooks/useFetch';
import { ReservationForAdmin } from 'src/shared/data-types/types';
import { Router, useMatch } from '@reach/router';
import {
  URL_FOR_FUTURE_RESERVATIONS,
  URL_FOR_RESERVATIONS_WITH_PAST,
} from 'src/shared/urls';
import IdentityHandler from '../identity-handler';
import Table from './table';
import Detail from './detail';
import TodayForPrint from './today-for-print';
import { OPTION, OPTIONS } from './query-params';
import { CommaSeparatedArrayParam } from '../components/utils';

const Reservations: FC = () => {
  const isIndexRoute = useMatch(`/admin/rezervace`);
  const [tablePage, setTablePage] = useState(1);
  const [activeOptions] = useQueryParam<string[]>(
    OPTIONS,
    withDefault(CommaSeparatedArrayParam as any, [])
  );

  const [urlForReservations, setUrlForReservations] = useState(
    URL_FOR_FUTURE_RESERVATIONS
  );

  const [fetchedReservations, loading] = useFetch<ReservationForAdmin>(
    urlForReservations,
    { defaultData: [], useAuthorization: true }
  );

  const [currentReservations, setCurrentReservations] = useState(
    fetchedReservations
  );

  useEffect(() => {
    setCurrentReservations(fetchedReservations);
  }, [fetchedReservations, setCurrentReservations]);

  useEffect(() => {
    if (isIndexRoute) {
      if (activeOptions.some(option => option === OPTION.PAST)) {
        setUrlForReservations(URL_FOR_RESERVATIONS_WITH_PAST);
      } else {
        setUrlForReservations(URL_FOR_FUTURE_RESERVATIONS);
      }
    }
  }, [activeOptions, isIndexRoute]);

  function setUpdatedReservation(reservation: ReservationForAdmin) {
    const orgReservations = [...currentReservations];

    const updatedReservation = orgReservations.find(
      reservations => reservations.code === reservation.code
    );

    if (updatedReservation) {
      orgReservations.splice(
        currentReservations.indexOf(updatedReservation),
        1,
        reservation
      );
      setCurrentReservations(orgReservations);
    }
  }

  return (
    <div
      css={css`
        height: 100vh;
        display: flex;
        flex-direction: column;
      `}
    >
      <nav
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: ${theme.spacing(1)};

          @media ${XS} {
            padding: ${theme.spacing(4)};
          }

          @media print {
            display: none;
          }
        `}
      >
        <div>
          <Brand />
        </div>
        <IdentityHandler />
      </nav>
      <main
        css={css`
          display: flex;
          padding: ${theme.spacing(4)} ${theme.spacing(1)};
          flex-direction: column;
          flex: 1 1 auto;

          @media ${XS} {
            padding: ${theme.spacing(4)} ${theme.spacing(4)};
          }
        `}
      >
        <Router
          css={css`
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
          `}
        >
          <Detail
            path=":code"
            reservations={currentReservations}
            onReservationCanceled={setUpdatedReservation}
          />
          <Table
            path="/"
            reservations={currentReservations}
            loading={loading}
            page={tablePage}
            onPageChange={setTablePage}
          />
          <TodayForPrint
            path="dnes-pro-tisk"
            reservations={currentReservations}
            loading={loading}
          />
        </Router>
      </main>
    </div>
  );
};

export default Reservations;
