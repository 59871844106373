import React, { FC, useEffect, useState } from 'react';
import { DataGrid, ColDef } from '@material-ui/data-grid';
import PrintIcon from '@material-ui/icons/Print';
import { addDays, format, isBefore, isToday, isTomorrow } from 'date-fns';
import { RouteComponentProps } from '@reach/router';
import { css } from 'styled-components';

import { ISODate, ReservationForAdmin } from 'src/shared/data-types/types';
import Button from 'components/shared/button';
import theme from 'src/shared/theme';
import { useQueryParam, withDefault } from 'use-query-params';
import { STATUS } from 'src/shared/data-types/enums';
import { useCallback } from 'react-transition-group/node_modules/@types/react';
import { Modal, TextField } from '@material-ui/core';
import { formatStatus } from './utils';
import { CommaSeparatedArrayParam } from '../components/utils';
import Chips from './chips';
import { OPTION, OPTIONS } from './query-params';
import { NewReservationModal } from './new-reservation-modal';

interface Props extends RouteComponentProps {
  className?: string;
  reservations: ReservationForAdmin[];
  loading: boolean;
  page: number;
  onPageChange(page: number): void;
}

interface Row {
  id: string;
  code: string;
  date: string;
  start: string;
  end: string;
  clientName: string;
  email: string;
  phone: string;
  animal: string;
  purpose: string;
  status: string;
}

const Table: FC<Props> = ({ reservations, loading, page, onPageChange }) => {
  const [activeOptions] = useQueryParam<string[]>(
    OPTIONS,
    withDefault(CommaSeparatedArrayParam as any, [])
  );

  const [rows, setRows] = useState<Row[]>([]);
  const columns = useColumns({ activeOptions });

  useEffect(() => {
    const reservationsAsRows = reservations
      .filter(filterReservation(activeOptions))
      // TODO: sort DESC when past is selected
      .map(reservation => ({
        ...reservation,
        id: reservation.code,
        date: format(new Date(reservation.start), `d.M.yyyy`),
        start: format(new Date(reservation.start), `H:mm`),
        end: format(new Date(reservation.end), `H:mm`),
      }));
    setRows(reservationsAsRows);
  }, [reservations, activeOptions]);

  const [newReservationModalOpened, setNewReservationModalOpened] = useState(
    false
  );

  return (
    <div
      css={css`
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      `}
    >
      {loading}
      <Chips
        label="Předvolby:"
        css={css`
          margin-bottom: ${theme.spacing(4)};
        `}
      />
      <div
        css={css`
          flex: 1 1 auto;
        `}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          page={page}
          onPageChange={param => {
            if (page !== param.page) onPageChange(param.page);
          }}
          autoPageSize
          hideFooterSelectedRowCount
          css={css`
            color: ${theme.palette.text.primary};
          `}
          data-testid="data-grid"
        />
      </div>
      <div
        css={css`
          display: flex;
          gap: 1rem;
          justify-content: end;
          margin-top: ${theme.spacing(4)};
        `}
      >
        <Button
          onClick={() => setNewReservationModalOpened(true)}
          variant="text"
          color="secondary"
          css={css`
            font-size: 1.125rem;
          `}
        >
          Založit rezervaci
        </Button>
        <Button
          to="/admin/rezervace/dnes-pro-tisk"
          variant="contained"
          color="primary"
        >
          <PrintIcon
            css={css`
              margin-right: ${theme.spacing(2)};
            `}
          />
          Dnes pro tisk
        </Button>
      </div>
      <NewReservationModal
        open={newReservationModalOpened}
        onClose={() => setNewReservationModalOpened(false)}
      />
    </div>
  );
};

export default Table;

const useColumns = ({ activeOptions }: { activeOptions: string[] }) => {
  const [columns, setColumns] = useState<ColDef[]>([]);

  useEffect(() => {
    const simplifiedView = activeOptions.some(
      option => option === OPTION.SIMPLIFIED
    );

    const columnsDefinition: ColDef[] = [
      {
        field: `detail`,
        headerName: `-`,
        flex: simplifiedView ? 50 : 40,
        renderCell({ getValue }) {
          return (
            <Button
              to={`/admin/rezervace/${getValue(`code`)}`}
              state={{ search: window.location.search }}
              size="small"
              variant="contained"
              color="primary"
              css={css`
                min-width: 0px;
              `}
            >
              {simplifiedView ? `Detail` : `...`}
            </Button>
          );
        },
      },
      {
        field: `date`,
        headerName: `Datum`,
        flex: 100,
        hide: activeOptions.some(f => f === OPTION.DATE),
      },
      {
        field: `start`,
        headerName: `Od`,
        flex: 65,
        hide: activeOptions.some(f => f === OPTION.START),
      },
      {
        field: `end`,
        headerName: `Do`,
        flex: 65,
        hide: simplifiedView || activeOptions.some(f => f === OPTION.END),
      },
      {
        field: `clientName`,
        headerName: `Jméno majitele`,
        flex: 200,
        hide: activeOptions.some(f => f === OPTION.CLIENTNAME),
      },
      {
        field: `email`,
        headerName: `Email`,
        flex: 130,
        hide: simplifiedView || activeOptions.some(f => f === OPTION.EMAIL),
      },
      {
        field: `phone`,
        headerName: `Telefon`,
        flex: 130,
        hide: simplifiedView || activeOptions.some(f => f === OPTION.PHONE),
      },
      {
        field: `animal`,
        headerName: `Zvíře`,
        flex: 50,
        hide: activeOptions.some(f => f === OPTION.ANIMAL),
      },
      {
        field: `purpose`,
        headerName: `Podrobnosti`,
        flex: 300,
        sortable: false,
        hide: activeOptions.some(f => f === OPTION.PURPOSE),
      },
      {
        field: `status`,
        headerName: `Stav`,
        flex: 130,
        valueFormatter(params) {
          return formatStatus(params.value as STATUS);
        },
        hide: simplifiedView || activeOptions.some(f => f === OPTION.CODE),
      },
      {
        field: `code`,
        headerName: `Kód`,
        flex: 80,
        hide: simplifiedView || activeOptions.some(f => f === OPTION.CODE),
      },
      {
        field: `id`,
        headerName: `id`,
        hide: true,
      },
    ];

    setColumns(columnsDefinition);
  }, [activeOptions]);

  return columns;
};

const filterReservation = (activeOptions: string[]) => {
  const past = activeOptions.some(f => f === OPTION.PAST);
  const today = activeOptions.some(f => f === OPTION.TODAY);
  const tomorrow = activeOptions.some(f => f === OPTION.TOMORROW);
  const dayAfterTomorrow = activeOptions.some(
    f => f === OPTION.DAY_AFTER_TOMORROW
  );
  const canceled = activeOptions.some(f => f === OPTION.CANCELED);

  return function reservationsFilter(reservation: ReservationForAdmin) {
    const reservationTime = new Date(reservation.start);

    if (!canceled && reservation.status === `CANCELED`) {
      return false;
    }

    if (past && isBefore(reservationTime, new Date())) {
      return true;
    }

    if (!today && !tomorrow && !dayAfterTomorrow) return true;

    return (
      (today && isToday(reservationTime)) ||
      (tomorrow && isTomorrow(reservationTime)) ||
      (dayAfterTomorrow && isTomorrow(addDays(reservationTime, -1)))
    );
  };
};
