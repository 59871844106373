import React, { FC } from 'react';
import Button from 'components/shared/button';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import theme from 'src/shared/theme';
import { css } from 'styled-components';
import useNetlifyIdentity from 'src/shared/hooks/useNetlifyIdentity';

const IdentityHandler: FC = ({ ...rest }) => {
  const { user, netlifyIdentity } = useNetlifyIdentity();

  return (
    <div {...rest}>
      {user ? (
        <Button onClick={() => netlifyIdentity.logout()}>
          <ExitToAppIcon
            css={css`
              margin-right: ${theme.spacing(2)};
            `}
          />
          Odhlásit se
        </Button>
      ) : (
        <Button onClick={() => netlifyIdentity.open()}>
          <VpnKeyIcon
            css={css`
              margin-right: ${theme.spacing(2)};
            `}
          />
          Příhlásit se
        </Button>
      )}
    </div>
  );
};

export default IdentityHandler;
