import { Variant } from '@material-ui/core/styles/createTypography';
import {
  navigate,
  RouteComponentProps,
  useParams,
  WindowLocation,
} from '@reach/router';
import Dialog from '@material-ui/core/Dialog';

import Button from 'components/shared/button';
import Error from 'components/shared/error';
import Spinner from 'components/shared/spinner/spinner';
import Text from 'components/shared/text';
import { ReservationForAdmin } from 'src/shared/data-types/types';
import { format } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import useIsMountedRef from 'src/shared/hooks/useIsMountedRef';
import getFlexGap from 'src/shared/styles/emulated-flex-gap';
import theme from 'src/shared/theme';
import bonzError from 'src/shared/utils/bonz-error';
import { css } from 'styled-components';
import { DialogContent, DialogTitle } from '@material-ui/core';
import getAuthorizationHeader from 'src/shared/get-authorization-header';
import defaultHeaders from 'src/shared/default-headers';
import { formatStatus } from './utils';
import { DEFAULT_QUERY_PARAMS } from './query-params';

interface Props extends RouteComponentProps {
  className?: string;
  reservations: ReservationForAdmin[];
  onReservationCanceled(reservation: ReservationForAdmin): void;
  location?: WindowLocation<{ search: string }>;
}

const Detail: FC<Props> = ({
  reservations,
  onReservationCanceled: reservationCanceled,
  location,
}) => {
  const { code } = useParams();
  const isMounted = useIsMountedRef();
  const [selectedReservation, setSelectedReservation] = useState<
    ReservationForAdmin
  >();
  const [error, setError] = useState<string>();
  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = useState(false);

  useEffect(() => {
    if (code !== null) {
      const reservationToSelect = reservations.find(
        reservation => reservation.code === code
      );
      if (reservationToSelect) {
        setSelectedReservation(reservationToSelect);
      } else {
        const QPs = location?.state.search || `?${DEFAULT_QUERY_PARAMS}`;
        // eslint-disable-next-line no-console
        console.warn(
          `Tryied to open detail of the reservation, not loaded in reservations list`
        );
        navigate(`/admin/rezervace${QPs}`);
      }
    }
  }, [code, reservations, location]);

  async function inactivateReservation() {
    try {
      const authorizationHeader: any = await getAuthorizationHeader();
      const response = await fetch(`/.netlify/functions/delete-reservation`, {
        method: `DELETE`,
        body: JSON.stringify({ code }),
        headers: new Headers({ ...defaultHeaders, ...authorizationHeader }),
      });

      if (response.status === 404 && isMounted) {
        setError(
          `Rezervace s daným kódem nebyla nalezena nebo již byla deaktivována.`
        );
      } else if (response.ok) {
        const canceledReservation = await response.json();

        // // TODO: component should be rerendered with right data
        // setSelectedReservation(canceledReservation);
        reservationCanceled(canceledReservation);
      }
    } catch (err) {
      bonzError(err);
      if (isMounted) {
        setError(`Nepovedlo se smazat rezervaci.`);
      }
    }

    setShouldShowDeleteDialog(false);
  }

  return (
    <>
      {selectedReservation ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: ${theme.breakpoints.width(`sm`)}px;
            margin: 0 auto;
            align-items: flex-start;
          `}
        >
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              ${getFlexGap(12)};
            `}
          >
            <Field
              title="Kód rezervace"
              value={selectedReservation.code}
              valueVariant="body2"
            />
            <Field
              title="Stav"
              value={formatStatus(selectedReservation.status)}
              valueVariant="body2"
            />
          </div>
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              ${getFlexGap(12)};
            `}
          >
            <Field
              title="Datum rezervace"
              value={format(new Date(selectedReservation.start), `d.M.yyyy`)}
            />
            <Field
              title="Od"
              value={format(new Date(selectedReservation.start), `H:mm`)}
              valueVariant="h4"
            />
            {selectedReservation.clientName.startsWith(`admin - `) ? (
              <>
                <Field
                  title="Do"
                  value={format(new Date(selectedReservation.end), `d.M.yyyy`)}
                  valueVariant="h4"
                />
                <Field
                  title="Čas"
                  value={format(new Date(selectedReservation.end), `H:mm`)}
                  valueVariant="h4"
                />
              </>
            ) : (
              <Field
                title="Do"
                value={format(new Date(selectedReservation.end), `H:mm`)}
                valueVariant="h4"
              />
            )}
          </div>
          <Field
            title="Jméno majitele"
            value={selectedReservation.clientName}
            valueVariant="h4"
          />
          <Field title="Email" value={selectedReservation.email} />
          <Field title="Telefon" value={selectedReservation.phone} />
          <Field title="Zvíře" value={selectedReservation.animal} />
          <Field title="Podrobnosti" value={selectedReservation.purpose} />
          {selectedReservation.status === `ACTIVE` ? (
            <Button
              onClick={() => setShouldShowDeleteDialog(true)}
              variant="contained"
              color="secondary"
              css={css`
                margin-top: ${theme.spacing(8)};
              `}
            >
              Zrušit tuto rezervaci
            </Button>
          ) : null}
          <Error error={error} />
          <Dialog
            onClose={() => setShouldShowDeleteDialog(false)}
            aria-labelledby="delete-dialog-title"
            open={shouldShowDeleteDialog}
          >
            <DialogTitle id="delete-dialog-title">
              Opravdu chcete zrušit tuto rezervaci?
            </DialogTitle>
            <DialogContent>
              <Text
                css={css`
                  max-width: 500px;
                `}
              >
                Rezervace bude převedena do stavu "Neaktivní", takže její čas
                bude uvolněn pro další rezervace.
              </Text>
              <Button
                onClick={inactivateReservation}
                variant="contained"
                color="primary"
                css={css`
                  margin-top: ${theme.spacing(4)};
                  float: right;
                `}
              >
                Zrušit
              </Button>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Detail;

const Field = ({
  title,
  value,
  className,
  valueVariant = `h5`,
}: {
  title: string;
  value: string;
  className?: string;
  valueVariant?: Variant;
}) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      margin-bottom: ${theme.spacing(4)};
      flex: 0 0 auto;
    `}
    className={className}
  >
    <Text variant="caption">{title}</Text>
    <Text variant={valueVariant}>{value}</Text>
  </div>
);
