import { RouteComponentProps } from '@reach/router';
import { ReservationForAdmin } from 'src/shared/data-types/types';
import { format, isToday } from 'date-fns';
import React, { FC, useEffect, useState } from 'react';
import { css } from 'styled-components';
import useFetch from 'src/shared/hooks/useFetch';
import { URL_FOR_FUTURE_RESERVATIONS } from 'src/shared/urls';
import { STATUS } from 'src/shared/data-types/enums';

interface Props extends RouteComponentProps {
  className?: string;
  reservations: ReservationForAdmin[];
  loading: boolean;
}

interface ReservationForPrint {
  start: string;
  end: string;
  clientName: string;
  purpose: string;
}

const TodayForPrint: FC<Props> = () => {
  const [fetchedReservations] = useFetch<ReservationForAdmin>(
    URL_FOR_FUTURE_RESERVATIONS,
    { defaultData: [], useAuthorization: true }
  );

  const [mappedreservations, setMappedreservations] = useState<
    ReservationForPrint[]
  >([]);

  useEffect(() => {
    const mapped: ReservationForPrint[] = fetchedReservations
      .filter(
        reservation =>
          isToday(new Date(reservation.start)) &&
          reservation.status === STATUS.ACTIVE
      )
      .map(reservation => ({
        start: format(new Date(reservation.start), `HH:mm`),
        end: format(new Date(reservation.end), `HH:mm`),
        clientName: reservation.clientName,
        purpose: reservation.purpose,
      }));

    setMappedreservations(mapped);
  }, [fetchedReservations]);

  return (
    <div>
      <h1
        css={css`
          font-weight: normal;
          text-align: center;
        `}
      >
        Přehled rezervací pro den
        <br />
        <b>{format(new Date(), `EEEE d.M.yyyy`)}</b>
      </h1>
      <table
        css={css`
          width: 100%;
          border: 1px solid #ddd;
          border-collapse: collapse;
          border-spacing: 0;
          text-align: left;

          td,
          th {
            padding: 0.5rem;
          }
        `}
      >
        <thead>
          <tr
            css={css`
              border: 2px solid #ddd;
            `}
          >
            <th
              css={css`
                width: 70px;
              `}
            >
              Od
            </th>
            <th
              css={css`
                width: 70px;
              `}
            >
              Do
            </th>
            <th>Majitel</th>
            <th>Popis</th>
          </tr>
        </thead>
        <tbody>
          {mappedreservations.map(reservation => (
            <tr
              css={css`
                border: 1px solid #ddd;
              `}
            >
              <td>{reservation.start}</td>
              <td>{reservation.end}</td>
              <td>{reservation.clientName}</td>
              <td>{reservation.purpose}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TodayForPrint;
