import { Link } from 'gatsby-theme-material-ui';
import React, { FC } from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { XS } from 'src/shared/media-query';
import TileButton from 'components/shared/tile-button';
import getFlexGap from 'src/shared/styles/emulated-flex-gap';
import styled, { css } from 'styled-components';

import { RouteComponentProps } from '@reach/router';
import { DEFAULT_QUERY_PARAMS } from './reservations/query-params';

interface Props extends RouteComponentProps {
  className?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100%;
  width: 100%;
`;

const Crossroad: FC<Props> = ({ ...rest }) => (
  <Container {...rest}>
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        ${getFlexGap(8)}

        @media ${XS}{
          flex-direction: row;
        }
      `}
    >
      <Link
        to={`/admin/rezervace?${DEFAULT_QUERY_PARAMS}`}
        underline="none"
        display="block"
        css={css`
            flex: 0 1 200px;
            height: 200px;
            width: 200px;
          `}
      >
        <TileButton
          Icon={() => <DateRangeIcon fontSize="large" />}
          caption="Plán rezervací"
        />
      </Link>
      <Link
        href="/admin/obsah/"
        underline="none"
        display="block"
        css={css`
            flex: 0 1 200px;
            height: 200px;
            width: 200px;
          `}
      >
        <TileButton
          Icon={() => <LibraryBooksIcon fontSize="large" />}
          caption="Editace obsahu"
        />
      </Link>
    </div>
  </Container>
);

export default Crossroad;
