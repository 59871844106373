import React, { FC } from 'react';
import { useQueryParam, withDefault } from 'use-query-params';
import { Chip } from '@material-ui/core';

import Text from 'components/shared/text';
import { css } from 'styled-components';
import getFlexGap from 'src/shared/styles/emulated-flex-gap';
import { OPTION, OPTIONS } from 'src/admin/reservations/query-params';
import { CommaSeparatedArrayParam } from '../../components/utils';
import { excludeAllFuture, isFuture } from './utils';

interface Props {
  className?: string;
  label?: string;
}

const Chips: FC<Props> = ({ className, label }) => {
  const [activeFilters, setActiveFilters] = useQueryParam<OPTION[]>(
    OPTIONS,
    withDefault(CommaSeparatedArrayParam as any, [])
  );

  const isActive = (title: string) =>
    activeFilters.some(value => value === title.toLowerCase());

  const getColor = (title: string) => {
    const selectedColor = title === OPTION.PAST ? `primary` : `secondary`;
    return isActive(title) ? selectedColor : `default`;
  };

  const handleTagClick = (title: string) => () => {
    const tagTitle = title.toLowerCase() as OPTION;

    if (!isActive(tagTitle)) {
      let tagsToActivate = [] as OPTION[];

      if (tagTitle === OPTION.PAST) {
        tagsToActivate = [
          tagTitle,
          ...excludeAllFuture(activeFilters),
        ];
      } else if (isFuture(tagTitle)) {
        tagsToActivate = [
          tagTitle,
          ...activeFilters.filter(f => f !== OPTION.PAST),
        ];
      } else {
        tagsToActivate = activeFilters ? [...activeFilters, tagTitle] : [tagTitle] as OPTION[];
      }
      setActiveFilters(tagsToActivate);
    } else {
      const tagsToActivate = activeFilters.filter(value => value !== tagTitle);
      setActiveFilters(tagsToActivate.length > 0 ? tagsToActivate : []);
    }
  };

  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;

        ${getFlexGap(2)};
      `}
    >
      {label ? <Text variant="h6">{label}</Text> : null}
      <Chip
        key={OPTION.TODAY}
        color={getColor(OPTION.TODAY)}
        onClick={handleTagClick(OPTION.TODAY)}
        label="Dnes"
      />
      <Chip
        key={OPTION.TOMORROW}
        color={getColor(OPTION.TOMORROW)}
        onClick={handleTagClick(OPTION.TOMORROW)}
        label="Zítra"
      />
      <Chip
        key={OPTION.DAY_AFTER_TOMORROW}
        color={getColor(OPTION.DAY_AFTER_TOMORROW)}
        onClick={handleTagClick(OPTION.DAY_AFTER_TOMORROW)}
        label="Pozítří"
      />
      <Chip
        key={OPTION.PAST}
        color={getColor(OPTION.PAST)}
        onClick={handleTagClick(OPTION.PAST)}
        label="Jen minulé"
      />
      <Chip
        key={OPTION.SIMPLIFIED}
        color={getColor(OPTION.SIMPLIFIED)}
        onClick={handleTagClick(OPTION.SIMPLIFIED)}
        label="Zjednodušené"
      />
      <Chip
        key={OPTION.CANCELED}
        color={getColor(OPTION.CANCELED)}
        onClick={handleTagClick(OPTION.CANCELED)}
        label="Zrušené"
      />
    </div>
  );
};

export default Chips;
