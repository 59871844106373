import { Modal, TextField, Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import React, { useCallback, useState } from 'react';
import Error from 'components/shared/error';
import { css } from 'styled-components';
import Button from 'components/shared/button';
import { LocalizedUtils } from 'components/reservations/new-reservation-form/date-picker/reservation-date-picker';
import { addMinutes, startOfToday, startOfTomorrow } from 'date-fns';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import csCZLocale from 'date-fns/locale/cs';
import { doFetch } from 'src/shared/hooks/useFetch';

export function NewReservationModal(props: {
  open: boolean;
  onClose: () => void;
}) {
  const { open, onClose } = props;
  const { handleSubmit, errors, trigger, register } = useForm();

  const [start, setStart] = useState(startOfToday());
  const [end, setEnd] = useState(startOfTomorrow());
  const [newReservationError, setNewReservationError] = useState<{
    message: string;
  }>();

  const setReservation = useCallback(
    async data => {
      if (start > end) {
        setNewReservationError({
          message: `Začátek rezervace musí být před koncem.`,
        });
        return;
      }

      const reservationData = {
        phone: `+420777777777`,
        ...data,
        start: start.toISOString(),
        end: end.toISOString(),
      };
      try {
        await doFetch(`/.netlify/functions/set-reservation`, {
          useAuthorization: true,
          data: reservationData,
        });
        onClose();
      } catch (err) {
        setNewReservationError(err);
      }
    },
    [start, end, onClose]
  );

  return (
    <Modal open={open} onClose={onClose}>
      <form
        onSubmit={handleSubmit(setReservation)}
        noValidate
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={csCZLocale}>
          <Paper
            css={css`
              padding: 2rem;
              max-width: 500px;
              display: flex;
              position: relative;
              flex-direction: column;
              gap: 1rem;
            `}
          >
            <Button
              type="button"
              onClick={onClose}
              css={css`
                position: absolute;
                right: 0;
                top: 0;
                background: none;
                border: none;
                min-width: 40px;
                min-height: 40px;
                padding: 0;
                font-size: 1.5rem;
                color: gray;
              `}
            >
              x
            </Button>
            <KeyboardDateTimePicker
              onChange={date => {
                if (date) {
                  setStart(date);
                  trigger(`start`);
                }
              }}
              minutesStep={15}
              value={start}
              ampm={false}
              disablePast
              label="Začátek"
              name="start"
            />
            <KeyboardDateTimePicker
              onChange={date => {
                if (date) {
                  setEnd(date);
                  trigger(`end`);
                }
              }}
              value={end}
              ampm={false}
              disablePast
              minutesStep={15}
              minDate={addMinutes(start, 15)}
              label="Konec"
              name="end"
              // non working because the input returns its formated output as value, instead of date
              // inputRef={register({
              //   validate: {
              //     BeforeStart: () =>
              //       isAfter(end, addMinutes(start, 15))
              //         ? false
              //         : `Rezervace musí končit nejdříve 15 min po jejím začátku`,
              //   },
              // })}
              helperText={
                <span
                  css={css`
                    color: red;
                  `}
                >
                  {errors?.end?.message}
                </span>
              }
            />
            <TextField
              id="purpose"
              name="purpose"
              InputProps={{ disableUnderline: true }}
              label="Důvod rezervace"
              variant="filled"
              required
              multiline
              rows={5}
              inputRef={register()}
            />
            {newReservationError ? <Error error={newReservationError} /> : null}
            <Button type="submit" variant="contained" color="primary">
              Potvrdit
            </Button>
          </Paper>
        </MuiPickersUtilsProvider>
      </form>
    </Modal>
  );
}
